import React, { Fragment } from 'react';

import { BrowserRouter, Routes, Route, /*Link*/ } from "react-router-dom";

// App.tsx
// import './App.css';
import { /*MyComponent,*/ defineCustomElements } from 'react-library';

defineCustomElements();

function App() {
  const value = 'World';
  return (
    <BrowserRouter basename='/'>
      <div>
        <h1>Check back soon...</h1>
        {/* <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </nav> */}
        <Routes>
          {/* <Route path="/" element={<Home  />} /> */}
          <Route path="/" element={<Home textArg={value} />} />
            {/* <Fragment>
              <div>Hello {value}</div>
              <MyComponent first='bob'></MyComponent>
            </Fragment>
          </Route> */}
          <Route path="about" Component={About} />
          <Route path="contact" Component={Contact} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

// react component which accepts a string property named banana

// Home Page
// const Home = (props: {textArg:string}) => (
const Home = ({textArg}: {textArg:string}) => (
  // <Fragment>
  //   <h1>Home</h1>
  //   <FakeText />
  // </Fragment>
  <Fragment>
     {/* need to figure out arg passing for routes
    <div>Hello {textArg}</div>
    <MyComponent first='bob'></MyComponent> */}
  </Fragment>
);
// About Page
const About = () => (
  <Fragment>
    <h1>About</h1>
    <FakeText />
  </Fragment>
);
// Contact Page
const Contact = () => (
  <Fragment>
    <h1>Contact</h1>
    <FakeText />
  </Fragment>
);

const FakeText = () => (
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
  </p>
)

export default App;

